<div class="header-pagina-home" style="width: 100%;
height: 500px;
margin-top: -53px;
background: red;
height: 45px;">
  <div class="logo">
    <div class="nome-logo">
      <div class="caixinha-nome-logo-span">
        <span class="nome-logo-span">Gira </span>
      </div>
      <div class="caixinha-nome-logo-span" style=" margin-top: -60px;">
        <span class="nome-logo-span">Pets</span>
      </div>
      <div>
        <span class="encontre-seu-amigo" style="color: #3A54B4;
    font: normal normal  31px/1.4em worksans-extralight,'work sans',sans-serif">Encontre aqui o seu melhor amigo</span>
      </div>
      <div class="botao-saiba-mais-1">
        <button class="botao-saiba-mais-home">Saiba mais</button>
      </div>
    </div>
    <img class="img-logo-principal" style="width: 479px;
    height: 478px; border-radius: 50%;" src="../../assets/dog-home.jpg" alt="Imagem home">
  </div>
</div>
<div class="container-home">

  <!-- Carrossel de fotos -->
  <!-- <div class="carousel">
  <div class="slides" [ngClass]="{
    'transition-next': currentSlideIndex !== null,
    'transition-prev': currentSlideIndex !== null
  }">
    <div *ngFor="let image of images; let i = index" class="slide"
         [ngClass]="{
           'active': i === currentSlideIndex,
           'next': i === nextSlideIndex,
           'prev': i === prevSlideIndex
         }">
      <img class="imagem-carrossel" style="width: 718px; height: 343px; border-radius: 8px;" [src]="images[currentSlideIndex]?.url" alt="Imagem do Carrossel">
      <div class="button-container">
        <button class="prev-button" (click)="prevSlide()"><i class="fa fa-chevron-left"
          id="icon-paginator-right" style="font-size: 25px; color: white; cursor: pointer;"></i></button>
          <h3 style="color: white;display: flex;
          align-items: center; " >Encontre aqui o seu melhor amigo!</h3>
        <button class="next-button" (click)="nextSlide()"><i class="fa fa-chevron-right"
          id="icon-paginator-right" style="font-size: 25px; color: white; cursor: pointer;"></i></button>
      </div>
    </div>
  </div>
</div> -->


  <div class="container-conheça-girapets">
    <p class="conheca-o-girapets-paragrafo" style="display: flex;
  justify-content: center;
  align-items: center; font-size: 55px; color: #3A54B4;">Conheça o Gira-pets</p>
    <p class="texto-conheca-girapets" style=" width: 50%; color: rgb(117, 117, 117);">Gira-Pets é um programa criado para estreitar laços entre pessoas que têm o
      sonho de adotar um
      pet e nossas ONGs e protetores parceiros. Vamos juntos incentivar a adoção,
      conscientizar sobre a posse responsável e fomentar a cultura de doação em prol do bem-estar animal.</p>
  </div>



  <div class="container-sobre-o-girapets">
    <p class="span-pets" style="display: flex;
  justify-content: center;
  align-items: center; font-size: 62px; color: white;">Sobre nossos animais</p>
    <div class="fotos-3-animais">
      <img class="img-animal1" style="width: 230px; height: 230px; border-radius: 50%;" src="../../assets/animal-logo-1.jpg"
        alt="Imagem home">
      <img class="img-animal1"  style="width: 230px; height: 230px; border-radius: 50%;" src="../../assets/animal-logo-2.jpg"
        alt="Imagem home">
      <img class="img-animal1"  style="width: 230px; height: 230px; border-radius: 50%;" src="../../assets/animal-logo-3.jpg"
        alt="Imagem home">
    </div>
    <p class="texto-sobre-animais" style="width: 50%; color: white;">Gira-Pets é um programa criado para estreitar laços entre pessoas que têm o
      sonho de adotar um
      pet e nossas ONGs e protetores parceiros. Vamos juntos incentivar a adoção,
      conscientizar sobre a posse responsável e fomentar a cultura de doação em prol do bem-estar animal.</p>
  </div>

  <div class="container-como-funciona">
    <span class="span-titulos" style="margin-top: 60px;">Entenda como funciona:</span>
    <div class="container-da-caixinha-passos-adocao">
      <div class="caixinha-passos-adocao">
        <i style="font-size: 70px; color: #3A54B4; " class="fa-solid fa-heart-circle-plus"></i>
        <span class="span-titulos"  style="margin-top: 10px;">Escolha seu pet</span>
        <span class="texto-passos-adocao" >Acesse a página de adoção através do botão <a style="text-decoration: underline;
          color: #615b5b;
          font-weight: bold;">Quero adotar</a> e escolha um pet. Lá você irá
          conhecer os pets das ONGs/protetores parceiros para criar a
           conexão perfeita com cão e/ou gato que busca um novo lar.</span>
      </div>
      <div class="caixinha-passos-adocao" style="height: 242px;">
        <i style="font-size: 70px; color: #3A54B4;"  class="fa fa-clipboard-list"></i>
        <span class="span-titulos"  style="margin-top: 10px;" >Formulário de interesse</span>
        <span class="texto-passos-adocao" >Preencha o formulário com suas informações e envie para avaliação. A ONG/protetor entrará em contato com você em até 24h.</span>
      </div>
      <div class="caixinha-passos-adocao">
        <i style="font-size: 70px; color: #3A54B4"  class=" fas fa-clipboard-check"></i>
        <span class="span-titulos"  style="margin-top: 10px;" >Avaliação da adoção</span>
        <span class="texto-passos-adocao" >A ONG/protetor parceiro irá fazer a análise do cadastro e perfil do
          adotante vs pet escolhido. Preenchendo os requisitos, você recebe a aprovação por Email ou Whatsapp informado no cadastro em até 24h</span>
      </div>
      <div class="caixinha-passos-adocao">
        <i style="font-size: 70px; color: #3A54B4;"  class=" fa-solid fa-dog"></i>
        <span class="span-titulos"  style="margin-top: 10px;" >Adoção Completa</span>
        <span class="texto-passos-adocao" >Caso seja aprovado pelo voluntário da ONG/protetor,
          você já pode levar seu pet para casa! Se enviou o formulário online,
           espere o contato e a aprovação.
          Com tudo certo, você busca seu pet em local e no dia combinado com a ONG/protetor.</span>
      </div>
    </div>
  </div>
</div>
