<span *ngIf="uploadedImages.length > 0">Imagens a serem adicionadas: </span>
<div class="manager-grid" *ngIf="atributosModal">
  <div class="imagens-upadas">
    <img *ngFor="let image of uploadedImages" style=" width: 160px;
    height: 180px;
    border-radius: 5px !important;
    min-width: 160px;
    min-height: 180px; margin-bottom: 10px;" [src]="image?.url" [alt]="image.name">
  </div>
  <div class="div-img">

    <!-- Botão upload -->

    <button style="margin-top: 20px;
    margin-bottom: 20px; border-radius: 6px;" class="botao-card-editar" type="button" (click)="fileInput.click()">
      <i class="pi pi-upload"></i>
      Adicionar novas fotos
    </button>
    <!-- Exibe as imagens upadas -->


    <!-- Exibe as imagens do serviço de imagens -->
    <img style=" width: 280px;
    height: 210px;
    border-radius: 5px !important;" [src]="'data:image/gif;base64,' + atributosModal?.imagens[arrayIndex]?.url"
      alt="Imagem">
    <span class="contador-imagens-edit">
      <div style="width: 130px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;">
        <button class="botao-paginador-edit" (click)="decrementIndex()"><i class="fa fa-chevron-left "
            id="icon-paginator-left" style="font-size: 40px; color: #636a72"></i></button>
        <button class="botao-paginador-edit" (click)="incrementIndex()"><i class="fa fa-chevron-right"
            id="icon-paginator-right" style="font-size: 40px; color: #636a72; cursor: pointer;"></i></button>
      </div>
      Imagem {{arrayIndex + 1}} de {{atributosModal?.imagens.length}}
    </span>
    <button style="background-color: transparent; border: none;" class="botao-lixeira" (click)="excluirImagem()">
      <i class="pi pi-trash" style="font-size: 20px;"></i>
    </button>

    <!-- Elemento input type="file" oculto -->
    <input style="display: none;" type="file" #fileInput (change)="onFileSelected($event)" accept=".jpg, .jpeg, .png"
      multiple class="hidden-file-input">
  </div>

  <div class="container-inputs-form">
    <div>
      <h3>Nome</h3>
      <div>
        <input type="text" class="input-add-animal" [(ngModel)]="atributosModal.nome" maxlength="25" />
        <div>
          <small style="color: red;" *ngIf="!atributosModal?.nome" class="p-invalid">Campo obrigatório.</small>
        </div>
        <div>
          <small>Caracteres restantes: {{25 - atributosModal?.nome.length}}</small>
        </div>
      </div>
    </div>

    <div>
      <h3>Especie</h3>
      <div>
        <input type="text" class="input-add-animal" [(ngModel)]="atributosModal.especie" maxlength="50" />
        <div>
          <small style="color: red;" *ngIf="!atributosModal?.especie" class="p-invalid">Campo obrigatório.</small>
        </div>
        <div>
          <small>Caracteres restantes: {{50 - atributosModal?.especie.length}}</small>
        </div>
      </div>
    </div>


    <div>
      <h3>Raça</h3>
      <div>
        <input type="text" class="input-add-animal" [(ngModel)]="atributosModal.raca" maxlength="50" />
        <div>
          <small style="color: red;" *ngIf="!atributosModal?.raca" class="p-invalid">Campo obrigatório.</small>
        </div>
        <div>
          <small>Caracteres restantes: {{50 - atributosModal?.raca.length}}</small>
        </div>
      </div>
    </div>


    <div>
      <h3>Idade</h3>
      <div>
        <input type="text" class="input-add-animal" [(ngModel)]="atributosModal.idade" maxlength="50" />
        <div>
          <small style="color: red;" *ngIf="!atributosModal.idade" class="p-invalid">Campo obrigatório.</small>
        </div>
        <div>
          <small>Caracteres restantes: {{50 - atributosModal?.idade.length}}</small>
        </div>
      </div>
    </div>


    <div>
      <h3>Descrição</h3>
      <div>
        <input type="text" class="input-add-animal" [(ngModel)]="atributosModal.descricao" maxlength="100" />
        <div>
          <small style="color: red;" *ngIf="!atributosModal.descricao" class="p-invalid">Campo obrigatório.</small>
        </div>
        <div>
          <small>Caracteres restantes: {{100 - atributosModal?.descricao.length}}</small>
        </div>
      </div>
    </div>

    <div class="container-dropdown">
      <h3>Estado - UF</h3>
      <p-dropdown class="editar-dropdown-cidades" [options]="estados"
      (onChange)="selecionarUF($event.value)"
      optionLabel="sigla"
      [(ngModel)]="atributosModal.uf"
      editable="true"
      filter="true"
      placeholder="Selecione um estado" dataKey="sigla"></p-dropdown>
    </div>

    <div class="container-dropdown" (click)="recuperarMunicipiosPorUf()">
      <h3>Município</h3>
      <p-dropdown class="editar-dropdown-cidades" [options]="municipios"
      (onChange)="selecionarMunicipio($event.value)"
      optionLabel="nome"
      [(ngModel)]="atributosModal.municipio"
      editable="true"
      filter="true"
      placeholder="Selecione" dataKey="nome"></p-dropdown>
    </div>

    <!-- <div class="container-dropdown" (click)="recuperarMunicipiosPorUf()">
      <h3>Município</h3>
      <p-dropdown class="editar-dropdown-cidades" [options]="municipios"
      (onChange)="selecionarMunicipio($event.value)"
      optionLabel="nome"
      [(ngModel)]="atributosModal.municipio"
      placeholder="Selecione um"
      filter="true"
      dataKey="nome"></p-dropdown>
    </div> -->

    <div>
      <h3>Cidade ou bairro</h3>
      <div>
        <input type="text" class="input-add-animal" [(ngModel)]="atributosModal.cidade" maxlength="100" />
        <div>
          <small style="color: red;" *ngIf="!atributosModal.cidade" class="p-invalid">Campo obrigatório.</small>
        </div>
        <div>
          <small>Caracteres restantes: {{100 - atributosModal?.cidade.length}}</small>
        </div>
      </div>
    </div>


    <div>
      <h3>Porte</h3>
      <div>
        <input type="text" class="input-add-animal" [(ngModel)]="atributosModal.porte" maxlength="100" />
        <div>
          <small style="color: red;" *ngIf="!atributosModal.porte" class="p-invalid">Campo obrigatório.</small>
        </div>
        <div>
          <small>Caracteres restantes: {{100 - atributosModal?.porte.length}}</small>
        </div>
      </div>
    </div>

    <div>
      <h3>Nome do dono</h3>
      <div>
        <input type="text" class="input-add-animal" [(ngModel)]="atributosModal.nome_dono" maxlength="100" />
        <div>
          <small>Caracteres restantes: {{100 - atributosModal?.nome_dono?.length}}</small>
        </div>
      </div>
    </div>
    <div>
      <h3>Telefone do dono</h3>
      <div>
        <input type="text" class="input-add-animal" [(ngModel)]="atributosModal.telefone_dono" maxlength="100" />
        <div>
          <small>Caracteres restantes: {{100 - atributosModal?.telefone_dono?.length}}</small>
        </div>
      </div>
    </div>

    <div style="width: 198px;">
      <h3>Sexo</h3>
      <div class="container-radio-button">
        <div class="caixinha-radio-button">
          <label style="display: flex;
        align-items: center;">
            <input style=" height: 18px;
          width: 18px;" type="radio" name="gender" value="Macho" [(ngModel)]="atributosModal.sexo">Macho
          </label>
        </div>
        <div class="caixinha-radio-button">
          <label style="display: flex;
        align-items: center;">
            <input style=" height: 18px;
          width: 18px;" type="radio" name="gender" value="Fêmea" [(ngModel)]="atributosModal.sexo">Fêmea
          </label>
        </div>
        <div>
          <small *ngIf="!atributosModal.sexo" class="p-invalid">Campo obrigatório.</small>
        </div>
      </div>
    </div>

    <div style="width: 198px;">
      <h3>Castrado?</h3>
      <div class="container-radio-button">
        <div class="caixinha-radio-button">
          <label style="display: flex;
        align-items: center;">
            <input style=" height: 18px;
          width: 18px;" type="radio" name="castrado" value="Sim" [(ngModel)]="atributosModal.castrado">Sim
          </label>
        </div>
        <div class="caixinha-radio-button">
          <label style="display: flex;
        align-items: center;">
            <input style=" height: 18px;
          width: 18px;" type="radio" name="castrado" value="Não" [(ngModel)]="atributosModal.castrado">Não
          </label>
        </div>
        <div>
          <small *ngIf="!atributosModal.sexo" class="p-invalid">Campo obrigatório.</small>
        </div>
      </div>
    </div>


    <div style="width: 198px;">
      <h3>Vermifugado?</h3>
      <div class="container-radio-button">
        <div class="caixinha-radio-button">
          <label style="display: flex;
        align-items: center;">
            <input style=" height: 18px;
          width: 18px;" type="radio" name="vermifugado" value="Sim" [(ngModel)]="atributosModal.vermifugado">Sim
          </label>
        </div>
        <div class="caixinha-radio-button">
          <label style="display: flex;
        align-items: center;">
            <input style=" height: 18px;
          width: 18px;" type="radio" name="vermifugado" value="Não" [(ngModel)]="atributosModal.vermifugado">Não
          </label>
        </div>
        <div>
          <small *ngIf="!atributosModal.sexo" class="p-invalid">Campo obrigatório.</small>
        </div>
      </div>
    </div>

    <div style="width: 198px;">
      <h3>Vacinado?</h3>
      <div class="container-radio-button">
        <div class="caixinha-radio-button">
          <label style="display: flex;
        align-items: center;">
            <input style=" height: 18px;
          width: 18px;" type="radio" name="vacinado" value="Sim" [(ngModel)]="atributosModal.vacinado">Sim
          </label>
        </div>
        <div class="caixinha-radio-button">
          <label style="display: flex;
        align-items: center;">
            <input style=" height: 18px;
          width: 18px;" type="radio" name="vacinado" value="Não" [(ngModel)]="atributosModal.vacinado">Não
          </label>
        </div>
        <div>
          <small *ngIf="!atributosModal.sexo" class="p-invalid">Campo obrigatório.</small>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="container-botoes-edit">
  <div class="caixinha-botoes-edit">
    <button class="botao-fechar" (click)="fecharModal()">Fechar</button>
    <button class="botao-salvar" (click)="editarAnimal()">Salvar</button>
  </div>
</div>
