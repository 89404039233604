<body>
  <p-toast></p-toast>
  <menu-sidebar [displaySideBar]="displaySideBar" (onClose)="fecharSideBar($event)"></menu-sidebar>

  <header class="header-novo">
    <nav style="width: 100%;display: flex;
    justify-content: center;">
      <div class="menu-icon">
        <i class="fa fa-bars fa-2xl" (click)="abrirSideBar()"></i>
        <div class="logo-botao-header-mobile">
          <a *ngIf="!isLoggedIn()" routerLink="/home">
            <h1 style="margin-left: -4px;
            font-family: cursive;
            display: flex;
            color: #3A54B4;
            justify-content: space-between;
            width: 140px;
            align-items: center;"><i class="fas fa-solid fa-paw"></i>Gira-Pets</h1>
          </a>
          <p-button *ngIf="isLoggedIn()"  class="botaoAdotar" style="margin-right: -5px; " routerLink="/gerenciar-animais"
            label="Gestão animais"></p-button>
          <p-button *ngIf="!isLoggedIn()" class="botaoAdotar" style="margin-right: -5px;" routerLink="/quero-adotar"
            label="Quero adotar"></p-button>
        </div>
      </div>


      <ul class="menu">

        <li class="li-header-app"><a routerLink="/home">
            <h1 style="font-family: cursive; font-size: 39px; display: flex;
            color: #3A54B4;
            justify-content: space-between;
            width: 240px;"><i style="margin-top: 4.5px;" class="fas fa-solid fa-paw"></i>Gira-Pets</h1>
          </a></li>
        <li class="li-header-app">
          <p-splitButton style=" color: #3A54B4;" label="Institucional" class="botao-faq" [model]="institucional"></p-splitButton>
        </li>
        <li class="li-header-app">
          <p-splitButton label="Contato" class="botao-faq" [model]="contato">

          </p-splitButton>
        </li>
        <li class="li-header-app">
          <a style="color: #3A54B4; font-size: 17px; font-family: Mulish, sans-serif; font-weight: 700;"
            routerLink="">Ajude-nos</a></li>
        <!-- <li class="li-header-app"><a style="color: rgb(117, 117, 117); font-size: 17px; font-family: cursive;"
            routerLink="">FAQ</a></li> -->

        <li class="li-header-app">
          <p-splitButton label="FAQ" class="botao-faq" [model]="links"></p-splitButton>
        </li>


        <div *ngIf="isLoggedIn()" class="container-dos-botoes">
          <p-button class="botaoAdotar" routerLink="/gerenciar-animais" label="Gerenciar animais"></p-button>
        </div>
        <div *ngIf="!isLoggedIn()" class="container-dos-botoes-deslogado">
          <p-button class="botaoAdotar" routerLink="/quero-doar" label="Quero doar"></p-button>
          <p-button class="botaoAdotar" routerLink="/quero-adotar" label="Quero adotar"></p-button>
          <p-button class="botaoEntrar" routerLink="/login" label="Entrar"></p-button>

        </div>



      </ul>
    </nav>
    <div *ngIf="isLoggedIn()">
      <div class="dropdown" (click)="toggleDropdown()">
        <button class="botao-perfil">
          <div class="icone-perfil">
            <i style="color: #346891;" class="fa-solid fa-user fa-2xl"></i>
          </div>
          <span>{{usuarioLogado}}</span>
        </button>

        <div class="dropdown-content" [ngClass]="{'show': isDropdownOpen}">
          <a routerLink="/gerenciar-animais" href="#">Meu perfil</a>
          <a (click)="logout()" href="#" routerLink="/login">Sair</a>
        </div>
      </div>

    </div>
  </header>




  <main style="display: flex; justify-content: center;">
    <div class="container" style="margin-top: 7%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 422px;">
      <router-outlet></router-outlet>
    </div>
  </main>

  <footer class="footer-main">
    <div class="footer-content">
      <div class="footer-info">
        <h4 style=" display: flex;
        justify-content: space-between;
        width: 200px;
        font-size: 20px;"><i class="fas fa-sharp fa-solid fa-paw" style="color: #ffffff;"></i>Sobre o Gira-Pets</h4>
        <p style="margin-top: -5.5px;">
          Gira-Pets é um programa criado para estreitar laços entre pessoas que têm o sonho de adotar um pet e nossas
          ONGs e protetores parceiros.
          Vamos juntos incentivar a adoção, conscientizar sobre a posse responsável e fomentar a cultura de doação em
          prol do bem-estar animal.
        </p>
      </div>

      <div class="footer-links">
        <ul class="ul-footer">
          <h3>Institucional</h3>
          <li class="li-footer"><a href="#">Sobre o Gira-Pets</a></li>
          <li class="li-footer"><a href="#">Os pets nas lojas</a></li>
          <li class="li-footer"><a href="#">Transparência com você</a></li>
          <li class="li-footer"><a href="#">Histórias de impacto</a></li>
          <li class="li-footer"><a href="#">Projetos sociais</a></li>
          <li class="li-footer"><a href="#">Unidades</a></li>
          <li class="li-footer"><a href="#">FAQ</a></li>
        </ul>
      </div>
      <div class="footer-social-media">
        <h3> Nossas redes sociais</h3>
        <ul class="ul-footer">
          <li style="list-style: none;">
            <a target="_blank" style="display: flex;
            justify-content: space-between;
            align-items: center;
            width: 101px;" href="https://www.instagram.com/protetoresdogirassol_go/?igshid=YmM0MjE2YWMzOA%3D%3D"> <img
                style="width: 27px;" src="../assets/instagram-icon.svg" alt=""> Instagram</a>
            <a target="_blank" style="display: flex;
            justify-content: space-between;
            align-items: center;
            width: 107px; margin-top:5px" href="https://api.whatsapp.com/send?phone=556196983324"> <img
                style="width: 27px;" src="../assets/whatsapp-icon.png" alt=""> WhatsApp</a>
          </li>
        </ul>
      </div>
    </div>
    <p class="footer-copy">&copy; 2023 Gira-Pets. Todos os direitos reservados.</p>
  </footer>
  <app-loading *ngIf="isActive"></app-loading>
</body>
