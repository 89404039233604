<div class="container-card-doacao">
  <img class="img-dogs-doacao" src="../../../assets/dogs-doacao.jpg" alt="">
  <div class="subcontainer-card-doacao">
    <label class="label-doacao">Doar</label>
    <label class="label-doacao">Precisamos do seu apoio para manter o site no ar !
      Não importa o tamanho da sua doação, cada gesto conta e
      faz nosso coração transbordar de alegria.
    </label>
    <a target="_blank" href="https://www.paypal.com/donate/?hosted_button_id=KCNVSND9Y3WAN">
      <input type="hidden" name="hosted_button_id" value="KCNVSND9Y3WAN" />
      <input type="image" src="https://www.paypalobjects.com/pt_BR/BR/i/btn/btn_donateCC_LG.gif" border="0"
        name="submit" title="PayPal - The safer, easier way to pay online!" alt="Faça doações com o botão do PayPal" />
      <img alt="" src="https://www.paypal.com/pt_BR/i/scr/pixel.gif" width="1" height="1" />
    </a>
  </div>

</div>
