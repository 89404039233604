<p-sidebar [(visible)]="displaySideBar" dismissable="false" class="responsive-sidebar" (onHide)="emitirEventoFecharSidebar()">
  <a routerLink="/home">
    <h1 style="width: 180px !important; color: white;"><i style="color: white;"
        class="fas fa-solid fa-paw"></i>Gira-Pets</h1>
  </a>
  <ul class="ul-sidebar">
    <li class="li-sidebar"> <a (click)="emitirEventoFecharSidebar()" (click)="rolarProTopo()"
        style="color: white; font-size: 20px;" routerLink="/home">Inicio</a></li>
    <li *ngIf="!isLoggedIn()" class="li-sidebar"><a (click)="emitirEventoFecharSidebar()"
        style="color: white; font-size: 20px;" routerLink="/login">Entrar</a></li>
    <li class="li-sidebar"><a (click)="emitirEventoFecharSidebar()" style="color: white; font-size: 20px;"
        routerLink="/quero-adotar">Quero adotar</a></li>
    <li class="li-sidebar"><a (click)="emitirEventoFecharSidebar()" (click)="sobreOGiraPets()"
        style="color: white; font-size: 20px;" routerLink="">Sobre o Gira-Pets</a></li>
    <li class="li-sidebar"><a (click)="emitirEventoFecharSidebar()" (click)="comoFuncionaScroll()"
        style="color: white; font-size: 20px;" routerLink="">Como funciona</a></li>
    <li class="li-sidebar"> <a (click)="emitirEventoFecharSidebar()" style="color: white; font-size: 20px;"
        routerLink=""></a>
        <p-dialog  class="modal-contatos" header="Contatos" [(visible)]="display">
          <div *ngFor="let contato of contato">
            <a target="_blank" style="color: #292525;" [href]="contato.url">
              <i [class]="contato.icon"></i>
              {{ contato.label }}
            </a>
          </div>
      </p-dialog>

      <button style="color: white;
      font-size: 20px; background: transparent; border: none; margin-left: -6.8px;" type="button" (click)="showDialog()" icon="pi pi-info-circle" label="Show">Contato</button>
      </li>
    <li class="li-sidebar"><a (click)="emitirEventoFecharSidebar()" style="color: white; font-size: 20px;"
        routerLink="">Ajude-nos</a></li>
    <li class="li-sidebar"><a (click)="emitirEventoFecharSidebar()" style="color: white; font-size: 20px;"
        routerLink="">Doação</a></li>


  </ul>
</p-sidebar>
