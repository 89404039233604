<form [formGroup]="animaisFormGroup" (ngSubmit)="addAnimal()">
  <div class="add-animal-container">


    <div class="container-corpo">
      <span *ngIf="uploadedImages.length > 0">Imagens a serem adicionadas: </span>
      <div class="div-imagens-upadas">
        <img class="imagem-upada" *ngFor="let image of uploadedImages" [src]="image?.url" [alt]="image.name">
      </div>
      <!-- Botão personalizado -->
      <button style="margin-top: 30px; margin-bottom: 40px;" type="button" class="botao-card-editar" (click)="fileInput.click()">
        <i class="pi pi-upload"></i>
        Selecionar foto
      </button>

      <!-- Elemento input type="file" oculto -->
      <input style="display: none;" type="file" #fileInput (change)="onFileSelected($event)" accept="image/*" multiple
         class="hidden-file-input">

    </div>

    <div class="container-formulario">
      <div class="campo-form">
        <h3>Nome</h3>
        <div>
          <input type="text" class="input-add-animal" pInputText formControlName="nome" placeholder="Ex: Peludinho" maxlength="40"/>
          <!-- <p>Caracteres restantes: {{ 40 - animaisFormGroup.get('nome').value.length }}</p> -->
        </div>
      </div>


      <div class="campo-form">
        <h3>Especie</h3>
        <div >
          <input type="text" class="input-add-animal" pInputText formControlName="especie" placeholder="Cachorro, gato, etc." maxlength="50"/>
          <!-- <p>Caracteres restantes: {{ 50 - animaisFormGroup.get('especie').value.length }}</p> -->
        </div>
      </div>


      <div class="campo-form">
        <h3>Raça</h3>
        <div >
          <input type="text" class="input-add-animal" pInputText formControlName="raça" placeholder="Ex: Bulldog" maxlength="50"/>
          <!-- <p>Caracteres restantes: {{ 50 - animaisFormGroup.get('raça').value.length }}</p> -->
        </div>
      </div>

      <div class="campo-form">
        <h3>Idade</h3>
        <div >
          <input type="text" class="input-add-animal" pInputText formControlName="idade" placeholder="Ex: 1 ano e seis meses" maxlength="50"/>
          <!-- <p>Caracteres restantes: {{ 50 - animaisFormGroup.get('idade').value.length }}</p> -->
        </div>
      </div>




      <div class="campo-form">
        <h3>Descrição</h3>
        <div >
          <input type="text" class="input-add-animal" pInputText formControlName="descricao" placeholder="Ex: Animal dócil e comportado" maxlength="100"/>
          <!-- <p>Caracteres restantes: {{ 100 - animaisFormGroup.get('descricao').value.length }}</p> -->
        </div>
      </div>

      <div class="campo-form">
        <h3>Porte</h3>
        <div >
          <input type="text" class="input-add-animal" pInputText formControlName="porte" placeholder="Ex: Grande, pequeno..." maxlength="100"/>
          <!-- <p>Caracteres restantes: {{ 100 - animaisFormGroup.get('descricao').value.length }}</p> -->
        </div>
      </div>

      <div class="container-dropdown">
        <h3>Estado - UF</h3>
        <p-dropdown class="editar-dropdown-cidades" [options]="estados"
        (onChange)="selecionarUF($event.value)"
        optionLabel="sigla"
        formControlName="uf"
        editable="true"
        filter="true"
        placeholder="Selecione um estado" dataKey="sigla"></p-dropdown>
      </div>

      <div class="container-dropdown" (click)="recuperarMunicipiosPorUf()">
        <h3>Município</h3>
        <p-dropdown class="editar-dropdown-cidades" [options]="municipios"
        (onChange)="selecionarMunicipio($event.value)"
        optionLabel="nome"
        formControlName="municipio"
        editable="true"
        filter="true"
        placeholder="Selecione o municipio" dataKey="nome"></p-dropdown>
      </div>

      <div class="campo-form">
        <h3>Cidade</h3>
        <div >
          <input type="text" class="input-add-animal" pInputText formControlName="cidade" placeholder="Ex: Girassol-GO" maxlength="100"/>
          <!-- <p>Caracteres restantes: {{ 100 - animalData.cidade.length }}</p> -->
        </div>
      </div>
      <div class="campo-form">
        <h3>Nome do dono</h3>
        <div >
          <input type="text" class="input-add-animal" pInputText formControlName="nome_dono" placeholder="Ex: João, maria..."
           maxlength="100"/>
          <!-- <p>Caracteres restantes: {{ 100 - animalData.cidade.length }}</p> -->
        </div>
      </div>
      <div class="campo-form">
        <h3>Telefone do dono</h3>
        <div >
          <input type="text" class="input-add-animal" pInputText formControlName="telefone_dono"
          placeholder="Ex: (61) 99541159"
           maxlength="100"/>
          <!-- <p>Caracteres restantes: {{ 100 - animalData.cidade.length }}</p> -->
        </div>
      </div>
    </div>
  </div>

  <div style="width: 198px;">
    <h3>Sexo</h3>
    <div class="container-radio-button" >
    <div class="caixinha-radio-button" >
      <label style="display: flex;
      align-items: center;">
        <input style=" height: 18px;
        width: 18px;" type="radio" name="gender" value="Macho" formControlName="sexo">Macho
      </label>
    </div>
    <div class="caixinha-radio-button">
      <label style="display: flex;
      align-items: center;">
        <input style=" height: 18px;
        width: 18px;" type="radio" name="gender" value="Fêmea" formControlName="sexo">Fêmea
      </label>
    </div>

  </div>
  </div>

  <div style="width: 198px;">
    <h3>Castrado?</h3>
    <div class="container-radio-button" >
      <div class="caixinha-radio-button" >
      <label style="display: flex;
      align-items: center;">
        <input style=" height: 18px;
        width: 18px;" type="radio" name="Castrado?" value="Sim" formControlName="castrado">Sim
      </label>
     </div>
    <div class="caixinha-radio-button">
      <label style="display: flex;
      align-items: center;">
        <input style=" height: 18px;
        width: 18px;" type="radio" name="Castrado?" value="Não" formControlName="castrado">Não
      </label>
    </div>

    </div>
  </div>

  <div style="width: 198px;">
    <h3>Vermifugado?</h3>
    <div class="container-radio-button" >
      <div class="caixinha-radio-button" >
      <label style="display: flex;
      align-items: center;">
        <input style=" height: 18px;
        width: 18px;" type="radio" name="Vermifugado?" value="Sim" formControlName="vermifugado">Sim
      </label>
     </div>
    <div class="caixinha-radio-button">
      <label style="display: flex;
      align-items: center;">
        <input style=" height: 18px;
        width: 18px;" type="radio" name="Vermifugado?" value="Não" formControlName="vermifugado">Não
      </label>
    </div>

    </div>
  </div>



  <div style="width: 198px;">
    <h3>Vacinado?</h3>
    <div class="container-radio-button" >
      <div class="caixinha-radio-button" >
      <label style="display: flex;
      align-items: center;">
        <input style=" height: 18px;
        width: 18px;" type="radio" name="vacinado?" value="Sim" formControlName="vacinado">Sim
      </label>
     </div>
    <div class="caixinha-radio-button">
      <label style="display: flex;
      align-items: center;">
        <input style=" height: 18px;
        width: 18px;" type="radio" name="vacinado?" value="Não" formControlName="vacinado">Não
      </label>
    </div>

    </div>
  </div>

  <div class="container-botoes-add-animal">
    <div class="caixinha-botoes-add-animal">
      <button class="botao-fechar" (click)="fecharModal()">Fechar</button>
      <button class="botao-salvar" type="submit">Salvar</button>
    </div>
  </div>
</form>
