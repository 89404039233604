<div class="container-tela-manager">
  <p-button class="botaoAdotar" label="Adicionar" icon="pi pi-plus" iconPos="center" (click)="addAnimal()"></p-button>

  <!-- container de cards -->
  <div class="container-animais" style="width: 83%; min-width: 380px;">

    <!-- cards de animais -->
    <p-confirmDialog #cd header="Confirmação" icon="pi pi-exclamation-triangle">
      <p-footer>
        <button type="button" pButton icon="pi pi-times" label="Voltar" (click)="cd.reject()"></button>
        <button style="background: white;
    color: #0d89ec;
    border-color: #0d89ec;" type="button" pButton icon="pi pi-check" label="Excluir" (click)="cd.accept()"></button>
      </p-footer>
    </p-confirmDialog>

    <div class="cards-caixa" style="justify-content: space-evenly; padding-top: 6%;">
      <div *ngFor="let objeto of animais" class="custom-card">
        <div class="custom-card-header">
          <img class="custom-card-image" [src]="'data:image/gif;base64,' + objeto?.imagens[0]?.url"
            alt="Imagem do Animal">
        </div>
        <div class="custom-card-content">
          <h2 class="custom-card-title">{{ objeto.nome }}</h2>
          <p class="custom-card-subtitle">{{ objeto.sexo }}</p>
          <p class="custom-card-description">{{ objeto.descricao }}</p>
        </div>
        <div class="botoes-card">
          <button type="button" class="botao-card-excluir" (click)="excluirAnimal(objeto.id)">Excluir</button>

          <button class="botao-card-editar" (click)="abrirModalEditar(objeto.id)">
            Editar
          </button>
          <button class="botao-detalhar-animal" (click)="detalharAnimal(objeto.id)">
            Detalhar animal
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
