<!-- <div class="header-modal">
  <div style="display: flex;
  width: 25px;
  height: 25px;
  justify-content: center;
  align-items: center;" (click)="FecharModal()">
  <i class="fa fa-x" style="font-size:19px;" (click)="FecharModal()"></i>
</div>
</div> -->


<div class="container-componente">
  <div class="paginador">

    <div class="container-imagem">
      <img class="imagem-paginador" [src]="'data:image/gif;base64,' + atributosModal?.imagens[arrayIndex]?.url"
        alt="Imagem">

      <span class="contador-imagens">
        <div style="width: 130px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;">
        <button class="botao-paginador" (click)="decrementIndex()"><i class="fa fa-chevron-left " id="icon-paginator-left"
          style="font-size: 40px; color: #636a72"></i></button>
          <button class="botao-paginador" (click)="incrementIndex()"><i class="fa fa-chevron-right" id="icon-paginator-right"
            style="font-size: 40px; color: #636a72; cursor: pointer;"></i></button>
          </div>
        Imagem {{arrayIndex + 1}} de {{atributosModal?.imagens.length}}</span>
    </div>


  </div>


  <div class="container-modal">

    <div class="container-ul">

      <div class="titulo-card-detail">
        <h1 style="display: flex;
      justify-content: flex-end;"> {{ atributosModal?.nome }} </h1>
        <i style="font-size: 25px;" class="fa-regular fa-heart" (click)="exibirMsgNaoImplementado()" ></i>
      </div>
      <div style="display: flex; justify-content: center; width: 341px;">
        <hr style=" width: 280px;
      border-top: 2px solid #e9e0e0;">
      </div>
      <ul class="primeira-lista-informacoes">
        <li class="li-modal-detail">
          <h3>Espécie</h3>
          {{atributosModal?.especie}}
        </li>
        <li class="li-modal-detail">
          <h3>Raça</h3>
          {{atributosModal?.raca}}
        </li>
        <li class="li-modal-detail">
          <h3>Idade</h3>
          {{atributosModal?.idade}}
        </li>
        <li class="li-modal-detail">
          <h3>Porte</h3>
          {{atributosModal?.porte}}
        </li>
        <li *ngIf="isLogged()" class="li-modal-detail">
          <h3>Nome do dono</h3>
          {{atributosModal?.nome_dono}}
        </li>
        <li *ngIf="isLogged()" class="li-modal-detail">
          <h3>Telefone do dono</h3>
          {{atributosModal?.telefone_dono}}
        </li>
        <li class="li-modal-detail">
          <h3>Sexo</h3>
          {{atributosModal?.sexo}}
        </li>
        <li class="li-modal-detail">
          <h3>Castrado?</h3>
          {{atributosModal?.castrado}}
        </li>
        <li class="li-modal-detail">
          <h3>Vacinado?</h3>
          {{atributosModal?.vacinado}}
        </li>
        <li class="li-modal-detail">
          <h3>Vermifugado?</h3>
          {{atributosModal?.vermifugado}}
        </li>

      </ul>
      <ul class="segunda-lista-informacoes">
        <li>
          <h3>Município/UF</h3>
          {{atributosModal?.municipio + "/" + atributosModal?.uf}}
        </li>
        <li >
          <h3>Cidade ou bairro</h3>
          {{atributosModal?.cidade}}
        </li>
        <li style="width: 340px;">
          <h3 >Descrição</h3>
          {{atributosModal?.descricao}}
        </li>

      </ul>
      <div class="botoes-container">
        <p-button label="Fechar" (click)="FecharModal()"></p-button>
        <p-button class="botao-card-animal-detalhar" routerLink="/login" label="Quero adotar"></p-button>
      </div>
    </div>


  </div>


</div>
